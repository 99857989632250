import styled from "styled-components";
export const Box = styled.div<{ src: string }>`
  width: 100vw;
  height: 100vh;
  background-image: url(${({ src }) => src});
  background-repeat: no-repeat;
  background-position: top right;
`;
export const Container = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
`;
export const Star = styled.div`
  display: flex;
  justify-content: center;
  margin-top: 40px;
  @media screen and (min-width: ${({ theme }) => theme.breakpoints.md}) {
    margin-top: 224px;
  }
  @media screen and (min-width: ${({ theme }) => theme.breakpoints.lg}) {
    margin-top: 252px;
  }
`;
export const TextThank = styled.span`
  font-family: ${({ theme }) => theme.font.Dancing};
  font-size: 64px;
  text-align: center;
  margin-top: 48px;
  @media screen and (min-width: ${({ theme }) => theme.breakpoints.md}) {
    margin-top: 80px;
  }
`;
export const TextDetails = styled.span`
  font-family: ${({ theme }) => theme.font.SF};
  color: ${({ theme }) => theme.colors.UIColorBlack};
  font-size: 14px;
  text-align: center;
  margin: 16px 16px;
  max-width: 343px;
  align-self: center;
`;
export const Name = styled.span`
  color: ${({ theme }) => theme.colors.UIColorBlack};
  font-weight: bold;
`;
