import styled from "styled-components";

export const Container = styled.div`
  position: sticky;
  top: 0px;
  height: 180px;
  width: 100%;
  display: flex;
  flex-direction: column;
  box-shadow: 0px 2px 10px #b0ada7;
`;
export const NavBar = styled.div`
  display: flex;
  justify-content: space-between;
  flex-direction: row;
  margin: 24px 16px 13px 16px;
  align-items: center;
  height: 40px;
`;
export const IconGermany = styled.img`
  width: 22px;
  height: 22px;
`;
export const IconInfo = styled.img`
  width: 20px;
  height: 20px;
  margin-left: 11px;
`;
export const TextTitle = styled.span`
  font-size: 24px;
  color: ${({ theme }) => theme.colors.UIColorDarkGray};
  font-family: ${({ theme }) => theme.font.NewYork};
  font-weight: normal;
  margin-left: 8px;
  display: inline-flex;
  @media screen and (max-width: 360px) {
    font-size: 16px;
  }
`;
export const ButtonClose = styled.button`
  width: 72px;
  height: 40px;
  border: none;
  border-radius: 8px;
  margin-left: 43px;
  background-color: ${({ theme }) => theme.colors.ButtonRed};
`;
export const TextClose = styled.span`
  font-size: 14px;
  color: ${({ theme }) => theme.colors.White};
  font-family: ${({ theme }) => theme.font.NewYorkBold};
  font-weight: bold;
`;
