import { gql, useQuery } from "@apollo/client";
import { useHistory } from "react-router-dom";

const GetData = (_id: string) => {
  const QrcodeExpire = gql`
    query ($uuid: String!) {
      isQrcodeExpire(uuid: $uuid) {
        isExpire
        wine {
          _id
          taste
          name
          image_url
          color
          year
        }
      }
    }
  `;
  const history = useHistory();
  const { data, error } = useQuery(QrcodeExpire, {
    variables: {
      uuid: _id,
    },
    onError: ({ graphQLErrors, networkError }) => {
      if (error || graphQLErrors || networkError) {
        history.push(`/${_id}/error`);
      }
    },
  });
  if (data?.isQrcodeExpire?.isExpire === true) {
    history.push(`/${_id}/expire`);
  }
  return data?.isQrcodeExpire?.wine;
};
export default GetData;
