function GetListWord(wordString: string, groupData: {}[]) {
  const newGroupData = groupData.sort(function (x: any, y: any) {
    let a = x.word.toLowerCase(),
      b = y.word.toLowerCase();
    return a === b ? 0 : a > b ? 1 : -1;
  });
  const word = newGroupData.filter((listWord: any) => {
    return listWord.word.toLowerCase() === wordString.toLowerCase();
  });
  let wordByInCludesWord = groupData.filter((listWord: any) => {
    return (
      listWord.word.toLowerCase().slice(0, wordString.length) ===
      wordString.toLowerCase()
    );
  });
  let wordByInCludesMeaning = groupData.filter((listWord: any) => {
    return listWord.meaning.toLowerCase().includes(wordString.toLowerCase());
  });
  wordByInCludesWord = wordByInCludesWord.filter((val: any) => {
    return !word.includes(val);
  });
  wordByInCludesMeaning = wordByInCludesMeaning.filter((val: any) => {
    return !wordByInCludesWord.includes(val);
  });
  wordByInCludesMeaning = wordByInCludesMeaning.filter((val: any) => {
    return !word.includes(val);
  });
  return word.concat(wordByInCludesWord, wordByInCludesMeaning);
}

export default GetListWord;
