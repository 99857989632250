import React from "react";
import WineStains from "Asset/WineStains.svg";
import BoxWrapScroll from "Components/Layout";
import {
  Container,
  TextDetails,
  Topic,
  Details,
  Image,
} from "./ErrorLink.styled";
import { withRouter } from "react-router-dom";
import text from "Data/Text";

const ErrorLink = () => {
  return (
    <BoxWrapScroll>
      <Container>
        <TextDetails>
          <Topic>{text.label_title_error}</Topic>
          <Details>
            {text.label_error_details}
          </Details>
        </TextDetails>
        <Image src={WineStains} />
      </Container>
    </BoxWrapScroll>
  );
};
export default withRouter(ErrorLink);
