import ListWord from "Components/Glossary/ListWord/ListWords";
import GetListWord from "Data/GetWords";
import text from "Data/Text";
import React from "react";
import { useEffect } from "react";
import Loader from "react-loader-spinner";
import styled from "styled-components";
const Container = styled.div`
  display: flex;
  flex-direction: column;
`;
const BoxNoResultsFound = styled.div`
  display: flex;
  flex-direction: column;
  margin: 30% auto auto auto;
`;
const TextNoResultsFound = styled.span`
  font-weight: normal;
  font-family: ${({ theme }) => theme.font.NewYork};
  font-size: 32px;
  text-align: center;
  color: ${({ theme }) => theme.colors.UIColorBlack};
`;
const Try = styled.span`
  font-weight: normal;
  margin-top: 16px;
  text-align: center;
  font-family: ${({ theme }) => theme.font.SF};
  font-size: 14px;
  color: ${({ theme }) => theme.colors.UIColorBlack};
`;
const Load = styled.div`
  display: flex;
  margin: auto auto;
  height: 100%;
  align-items: center;
`;
type TypeListResults = {
  setOpenMeaning: (setOpenMeaning: boolean) => void;
  setCloseSearch: (setCloseSearch: boolean) => void;
  setFromSearch: (setCloseSearch: boolean) => void;
  getSelectedWord: (getSelectedWord: string) => void;
  meaningSelected: (getSelectedWord: string | undefined) => void;
  allListWords: {}[]
  wordInRow: string;
};
const ListResults = ({
  setOpenMeaning,
  getSelectedWord,
  setCloseSearch,
  setFromSearch,
  meaningSelected,
  wordInRow,
  allListWords
}: TypeListResults) => {
  const data = GetListWord(wordInRow,allListWords)
  useEffect(()=>{
    localStorage.setItem('ListResult',wordInRow)
  },[wordInRow])

  return data?(
    data.length ===0? (
      <BoxNoResultsFound>
        <TextNoResultsFound>{text.label_text_no_results}</TextNoResultsFound>
        <Try>{text.label_text_try_again}</Try>
      </BoxNoResultsFound>
    ) : (
      <>
        {data?.map((item: any) => (
          <Container key={item?.word}>
            <ListWord
              meaningSelected={meaningSelected}
              fromSearch={true}
              setFromSearch={setFromSearch}
              describe={item.meaning}
              disableDescribe={false}
              key={item?.word}
              setCloseSearch={setCloseSearch}
              setOpenMeaning={setOpenMeaning}
              getSelectedWord={getSelectedWord}
              wordInRow={item?.word}
              iconRed={true}
            />
          </Container>
        ))}
      </>
    )
  ) : (
    <Load>
      <Loader type="Oval" color="#EA1A20" width={100} height={100} />
    </Load>
  );
};
export default ListResults;
