import styled from 'styled-components';

const AppTitle = styled.div`
  color: #444;
  font-size: 50px;
  line-height: 50px;
  height: 50px;
  text-align: center;
  font-weight: 300;
`;

const Layout = styled.div`
  width: 100vw;
  height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

const Home = () => {
  return (
    <Layout>
      <AppTitle>
        <b>Wein</b>keller
      </AppTitle>
    </Layout>
  );
};

export default Home;
