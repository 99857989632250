const findGroupData = (groupName: any) => {
  const uniqueSet = groupName?.filter((item: any, index: any) => {
    return groupName.indexOf(item) === index;
  });
  const seperateName = uniqueSet?.reduce((acc: any, word: any) => {
    const letter = word[0].toLowerCase();
    acc[letter] = (acc[letter] || []).concat(word);
    return acc;
  }, []);
  let countGroup: any;
  if (seperateName) {
    let addLater: any = [];
    countGroup = Object.entries(seperateName).map(([letter, names]) => {
      if (letter === "ä") {
        addLater = [...addLater, letter.toUpperCase()];
        return null;
      } else if (letter === "ö") {
        addLater = [...addLater, letter.toUpperCase()];
        return null;
      } else if (letter === "ü") {
        addLater = [...addLater, letter.toUpperCase()];
        return null;
      } else if (letter === "ß") {
        addLater = [...addLater, letter.toUpperCase()];
        return null;
      } else {
        return letter.toUpperCase();
      }
    });

    countGroup = countGroup.filter((item: any) => {
      return item !== null;
    });
    let lengthOfAddLater = addLater.length;
    let index = 0;
    while (lengthOfAddLater > 0) {
      countGroup.push(addLater[index]);
      index += 1;
      lengthOfAddLater -= 1;
    }
  }
  return countGroup;
};
export default findGroupData;
