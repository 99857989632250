const getListWithSpecialLetter = (groupName: any) => {
  const uniqueSet = groupName.filter((item: any, index: any) => {
    return groupName?.indexOf(item) === index;
  });
  const newGroup = uniqueSet.sort((a: any, b: any) => b.localeCompare(a));
  const seperateName = newGroup?.reverse().reduce((acc: any, word: any) => {
    const letter = word[0].toLowerCase();
    acc[letter] = (acc[letter] || []).concat(word);
    return acc;
  }, []);
  let groupWithSpecialLetter;
  if (seperateName) {
    let addLater: any = [];
    groupWithSpecialLetter = Object.entries(seperateName).map(
      ([letter, names]) => {
        if (letter === "ä") {
          addLater = [...addLater, seperateName[letter]];
          return null;
        } else if (letter === "ö") {
          addLater = [...addLater, seperateName[letter]];
          return null;
        } else if (letter === "ü") {
          addLater = [...addLater, seperateName[letter]];
          return null;
        } else if (letter === "ß") {
          addLater = [...addLater, seperateName[letter]];
          return null;
        } else {
          return names;
        }
      }
    );
    groupWithSpecialLetter = groupWithSpecialLetter.filter((item: any) => {
      return item !== null;
    });

    let lengthOfAddLater = addLater.length;
    let index = 0;
    while (lengthOfAddLater > 0) {
      groupWithSpecialLetter.push(addLater[index]);
      index += 1;
      lengthOfAddLater -= 1;
    }
  }
  return groupWithSpecialLetter;
};

export default getListWithSpecialLetter;
