import React from "react";
import styled from "styled-components";
import icon_wine from "Asset/WineIcon.svg";
import text from "Data/Text";
const Container = styled.div`
  background: rgba(28, 28, 30, 0.6);
  position: fixed;
  top: 0px;
  left: 0px;
  width: 100%;
  height: 100%;
  display: flex;
  z-index: 10;
  #root {
    overflow: hidden;
  }
`;
const BoxConfirm = styled.div`
  border-radius: 8px;
  width: 300px;
  height: auto;
  display: flex;
  flex-direction: column;
  margin: auto auto;
  background-color: ${({ theme }) => theme.colors.White};
  @media screen and (min-width: ${({ theme }) => theme.breakpoints.md}) {
    width: 338px;
  }
  @media screen and (min-width: ${({ theme }) => theme.breakpoints["1.5xl"]}) {
    width: 512px;
  }
`;
const TextTitle = styled.span`
  font-size: 18px;
  color: ${({ theme }) => theme.colors.UIColorBlack};
  font-family: ${({ theme }) => theme.font.NewYorkBold};
  font-weight: bold;
  margin-left: 14px;
`;
const TextDescribe = styled.span`
  font-size: 16px;
  color: ${({ theme }) => theme.colors.UIColorBlack};
  font-family: ${({ theme }) => theme.font.SF};
  font-weight: normal;
  margin: 26px 24px 0px 24px;
  @media screen and (max-width: ${({ theme }) => theme.breakpoints.sm}) {
    font-size: 20px;
  }
`;
const GroupButton = styled.div`
  display: flex;
  flex-direction: row;
  margin-top: 45px;
  height: 100%;
  width: 100%;
  margin-bottom: 30px;
`;
const GroupTitle = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  font-size: 24px;
  color: ${({ theme }) => theme.colors.UIColorDarkGray};
  font-family: ${({ theme }) => theme.font.NewYork};
  font-weight: normal;
  margin: 41px 0px 0px 30px;
  @media screen and (max-width: ${({ theme }) => theme.breakpoints.sm}) {
    font-size: 20px;
  }
`;
const Button = styled.button<{ submit: boolean }>`
  font-size: 18px;
  color: ${({ theme, submit }) =>
    submit ? theme.colors.ButtonRed : theme.colors.UIColorGray};
  font-family: ${({ theme }) => theme.font.NewYorkBold};
  font-weight: normal;
  border: none;
  background-color: transparent;
  width: 50%;
`;
const IconWine = styled.img`
  color: ${({ theme }) => theme.colors.UIColorDarkGray};
  width: 24px;
  height: 24px;
`;

type TypeConfirm = {
  setConfirmButton: (setConfirm: boolean) => void;
  setConfirmOpen: (setConfirmOpen: boolean) => void;
  nameReviewer: string;
};
const Confirm = ({
  setConfirmButton,
  setConfirmOpen,
  nameReviewer,
}: TypeConfirm) => {
  const onCancel = () => {
    setConfirmOpen(false);
  };
  const onConfirm = () => {
    setConfirmOpen(false);
    setConfirmButton(true);
  };
  return (
    <Container>
      <BoxConfirm>
        <GroupTitle>
          <IconWine src={icon_wine} />
          <TextTitle>{text.label_title_confirm}</TextTitle>
        </GroupTitle>
        <TextDescribe>
          {text.describe_confirm}
          <span style={{ fontWeight: "bold" }}>{nameReviewer}</span> ?
        </TextDescribe>
        <GroupButton>
          <Button submit={false} onClick={onCancel}>
            {text.label_button_cancle}
          </Button>
          <Button submit={true} onClick={onConfirm}>
            {text.label_button_submit}
          </Button>
        </GroupButton>
      </BoxConfirm>
    </Container>
  );
};
export default Confirm;
