const theme = {
    colors: {
      ButtonRed: '#EA1A20',
      RedWine2: '#C42D31',
      UIColorBlack: '#1C1C1E',
      UIColorGray: '#8E8E93',
      UIColorDarkGray: '#3A3A3C',
      LightRed1: '#FF453A',
      LightRed3: '#FDE8E9',
      Error: '#FF3B30',
      White: '#FFFFFF',
      UIColorLightgray1: '#F2F2F7',
      UIColorLightgray2: '#E5E5EA',
      Black: '#000000',
      SuccessLight: '#EBF9EE',
      Success: '#34C759',
    },
    breakpoints: {
      sm: '375px',
      md: '768px',
      lg: '1024px',
      xl: '1280px',
      '1.5xl': '1440px',
      '2xl': '1535px',
    },
    zIndex: {
      '0': 0,
      '10': 10,
      '20': 20, 
      '30': 30,
      '40': 40,
      '50': 50,
    },
    font: {
      Dancing: "DancingScriptRegular",
      NewYork: "NewYorkSmallRegular",
      SF: "SFProTextRegular",
      NewYorkBold: "NewYorkSmallBold"
    }
  }
  
  export default theme