import React from "react";
import { useParams, withRouter } from "react-router-dom";
import StarIcon from "@material-ui/icons/Star";
import ReactStars from "react-rating-stars-component";
import Background from "Asset/Illustration.svg";
import BoxWrapScroll from "Components/Layout";
import {
  Box,
  Container,
  Star,
  TextThank,
  TextDetails,
  Name,
} from "Page/Success.styled";
import text from "Data/Text";
const thirdExample = {
  size: 25,
  count: 5,
  value: 0,
  color: "#C7C7CC",
  emptyIcon: <StarIcon fontSize="large" />,
  filledIcon: <StarIcon fontSize="large" />,
  activeColor: "#FF453A",
  classNames: "star",
  edit: false,
};
const Success = () => {
  const { star }: { star: string } = useParams();
  const { name }: { name: string } = useParams();
  const valueStar = { star };
  var num: number = +valueStar.star;
  return (
    <BoxWrapScroll>
      <Box src={Background}>
        <Container>
          <Star>
            <ReactStars {...thirdExample} value={num} />
          </Star>
          <TextThank>{text.label_title_thank}</TextThank>
          <TextDetails>
          {text.label_expire_review_of} <Name>{name}</Name> {text.label_expire_review_details}
          </TextDetails>
        </Container>
      </Box>
    </BoxWrapScroll>
  );
};
export default withRouter(Success);
