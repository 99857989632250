import React from "react";
import styled from "styled-components";
import icon_next from "Asset/Next.svg";
import icon_next_red from "Asset/icon_next_red.svg";

const EachRow = styled.div<{ check: boolean; paddingFromSearch: boolean }>`
  height: ${({ check }) => (check ? "48px" : "119px")};
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  margin: ${({ paddingFromSearch }) =>
    paddingFromSearch ? "0px 16px 0px 16px" : "0px 0px 0px 16px"};
  border-bottom: 1px solid ${({ theme }) => theme.colors.UIColorLightgray2};
`;
const Word = styled.span<{ textInSearch: boolean }>`
  display: flex;
  font-family: ${({ theme, textInSearch }) =>
    textInSearch ? theme.font.NewYork : theme.font.SF};
  font-size: ${({ textInSearch }) => (textInSearch ? "24px" : "16px")};
  width: 100%;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  overflow: hidden;
  -webkit-box-orient: vertical;
  color: ${({ theme }) => theme.colors.UIColorBlack};
`;
const IconNext = styled.img<{ fromSearch: boolean }>`
  display: flex;
  margin-right: ${({ fromSearch }) => (fromSearch ? "10px" : "18px")};
`;
const Describe = styled.span`
  align-self: flex-start;
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  font-size: 14px;
  width: 280px;
  color: ${({ theme }) => theme.colors.UIColorDarkGray};
  font-family: ${({ theme }) => theme.font.SF};
  font-weight: normal;
  margin: 8px 0px 0px 0px;
  @media screen and (min-width: ${({ theme }) => theme.breakpoints.sm}) {
    width: 343px;
  }
`;
const Box = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
`;
type TypeListWord = {
  wordInRow: string;
  iconRed: boolean;
  describe: string;
  disableDescribe: boolean;
  setFromSearch: (setFromSearch: boolean) => void;
  setCloseSearch: (setCloseSearch: boolean) => void;
  setOpenMeaning: (setOpenMeaning: boolean) => void;
  getSelectedWord: (getSelectedWord: string) => void;
  meaningSelected: (meaningSelected: string | undefined) => void;
  fromSearch: boolean;
};
const ListWord = ({
  wordInRow,
  setOpenMeaning,
  getSelectedWord,
  setCloseSearch,
  setFromSearch,
  iconRed,
  describe,
  disableDescribe,
  fromSearch,
  meaningSelected,
}: TypeListWord) => {
  const onOpenMeaning = () => {
    getSelectedWord(wordInRow);
    setCloseSearch(false);
    setOpenMeaning(true);
    setFromSearch(fromSearch);
    meaningSelected(describe);
  };

  return (
    <EachRow
      paddingFromSearch={fromSearch}
      onClick={onOpenMeaning}
      check={disableDescribe}
    >
      <Box>
        <Word textInSearch={fromSearch}>{wordInRow}</Word>
        <IconNext
          fromSearch={fromSearch}
          src={iconRed ? icon_next_red : icon_next}
        />
      </Box>
      {!disableDescribe && <Describe>{describe}</Describe>}
    </EachRow>
  );
};
export default ListWord;
