import BaseNav from "Components/Glossary/BaseNav/BaseNav";
import text from "Data/Text";
import React, { Fragment } from "react";
import { useEffect } from "react";
import { useState } from "react";
import styled from "styled-components";
import SearchBar from "../SearchBar";

type TypeSearch = {
  setResultOpen: (setResultOpen: boolean) => void;
  setModalOpen: (setModalOpen: boolean) => void;
  setWordTypeing: (setWordTypeing: string) => void;
  setOpenSearch: (setOpenSearch: boolean) => void;
  word: string;
};

const Container = styled.div<{ showBox: boolean }>`
  display: ${({ showBox }) => (showBox ? "flex" : "none")};
  flex-direction: row;
  margin: 24px 16px 0px 16px;
  padding-bottom: 16px;
`;
const TextResults = styled.span`
  font-size: 14px;
  min-width: max-content;
  color: ${({ theme }) => theme.colors.ButtonRed};
  font-family: ${({ theme }) => theme.font.SF};
`;
const WordResult = styled.span`
  font-size: 14px;
  font-weight: bold;
  margin-left: 4px;
  color: ${({ theme }) => theme.colors.ButtonRed};
  font-family: ${({ theme }) => theme.font.SF};
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
`;
const SearchGlossary = ({
  setResultOpen,
  setModalOpen,
  setWordTypeing,
  setOpenSearch,
  word,
}: TypeSearch) => {
  const [display, setDisplay] = useState(false);
  const onOpenResult = () => {
    setOpenSearch(false);
    setResultOpen(true);
  };
  useEffect(() => {
    word !== "" ? setDisplay(true) : setDisplay(false);
  }, [word]);
  return (
    <Fragment>
      <BaseNav
        inFirstPage={true}
        currentPage={setOpenSearch}
        title={text.label_title_search}
        goBack={setOpenSearch}
        showButton={false}
        setOpenModal={setModalOpen}
      />
      <SearchBar
        mainPage={false}
        search={true}
        setSearchOpen={setOpenSearch}
        disable={false}
        word={setWordTypeing}
        setResultOpen={setResultOpen}
      />
      <Container showBox={display} onClick={onOpenResult}>
        <TextResults>{text.label_see_result}</TextResults>
        <WordResult>{word}</WordResult>
      </Container>
    </Fragment>
  );
};
export default SearchGlossary;
