import styled from 'styled-components';
export const Container = styled.div`
  display: flex;
  flex-direction: column;
  padding-left: 16px;
  margin-top: 24px;
  @media screen and (min-width: ${({ theme }) => theme.breakpoints.md}) {
    padding-left: unset;
    margin-top: unset;
  }
`;
export const Details = styled.div`
  display: flex;
  flex-direction: row;
  margin-top: 13px;
  @media screen and (min-width: ${({ theme }) => theme.breakpoints.md}) {
    flex-direction: column;
  }
`;
export const LabelHead = styled.span`
  display: flex;
  font-family: ${({ theme }) => theme.font.Dancing};
  font-size: 24px;
  color: ${({ theme }) => theme.colors.UIColorBlack};
`;
export const ImageWine = styled.div<{ src: string }>`
  background-image: url(${({ src }) => src});
  width: 80px;
  height: 100px;
  border-radius: 4px;
  background-position: center;
  background-size: cover;
  box-shadow: 0px 8px 10px rgba(0, 0, 0, 0.16);
  @media screen and (min-width: ${({ theme }) => theme.breakpoints.md}) {
    flex-direction: row;
    width: 204px;
    height: 255px;
    box-shadow: 0px 20.4px 25.5px rgba(0, 0, 0, 0.16);
    border-radius: 10.2px;
  }
  @media screen and (min-width: ${({ theme }) => theme.breakpoints.lg}) {
    height: 309px;
    width: 247px;
  }
`;
export const GroupData = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-left: 16px;
  margin-right: 16px;
  align-self: flex-start;
  > span {
  }
  @media screen and (min-width: ${({ theme }) => theme.breakpoints.md}) {
    margin-top: 48px;
    margin-left: unset;
    margin-right: unset;
  }
`;
export const BoxDetails = styled.div`
  display: flex;
  flex-direction: column;
  overflow-y: scroll;
  margin-bottom: 16px;
  height: 100%;
`;
export const NameWine = styled.span`
  font-family: ${({ theme }) => theme.font.NewYork};
  font-size: 24px;
  color: ${({ theme }) => theme.colors.UIColorBlack};
  @media screen and (min-width: ${({ theme }) => theme.breakpoints.md}) {
    font-size: 32px;
    max-width: 204px;
    display: flex;
    overflow-wrap: anywhere;
  }
  @media screen and (min-width: ${({ theme }) => theme.breakpoints.lg}) {
    max-width: 247px;
  }
`;
export const YearWine = styled.span`
  font-size: 14px;
  margin-top: 2px;
  color: ${({ theme }) => theme.colors.UIColorGray};
  font-family: ${({ theme }) => theme.font.SF};
  @media screen and (min-width: ${({ theme }) => theme.breakpoints.md}) {
    font-size: 16px;
    margin-top: 8px;
  }
`;
export const TypeWine = styled.span`
  font-size: 12px;
  color: ${({ theme }) => theme.colors.RedWine2};
  font-family: ${({ theme }) => theme.font.NewYork};
  font-weight: bold;
  margin-top: 8px;
  @media screen and (min-width: ${({ theme }) => theme.breakpoints.md}) {
    font-size: 14px;
    margin-top: 16px;
  }
`;
export const TasteWine = styled.span<{ show: boolean }>`
  font-size: 12px;
  max-width: 235px;
  color: ${({ theme }) => theme.colors.UIColorDarkGray};
  font-family: ${({ theme }) => theme.font.SF};
  font-weight: normal;
  margin-top: 8px;
  display: ${({ show }) => (show ? 'none' : 'flex')};
  @media screen and (min-width: ${({ theme }) => theme.breakpoints.md}) {
    font-size: 14px;
  }
`;
export const TasteTopic = styled.span<{ hide: boolean }>`
  font-size: 10px;
  color: ${({ theme }) => theme.colors.DarkGray};
  font-family: ${({ theme }) => theme.font.SF};
  font-weight: bold;
  margin-top: 8px;
  display: ${({ hide }) => (hide ? 'none' : 'flex')};
  @media screen and (min-width: ${({ theme }) => theme.breakpoints.md}) {
    font-size: 12px;
    margin-top: 16px;
  }
`;

export const ModalLoading = styled.div`
  position: fixed;
  top: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
  z-index: 200;
  background: rgba(28, 28, 30, 0.6);
  display: flex;
  align-items: center;
  justify-content: center;
`;
