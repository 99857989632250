import BaseNav from "Components/Glossary/BaseNav/BaseNav";
import text from "Data/Text";
import React, { Fragment } from "react";
import { useEffect } from "react";
import { useState } from "react";
import styled from "styled-components";
import ListResults from "../../ListResults/ListResults";

type TypeSearch = {
  setSearchOpen: (setSearchOpen: boolean) => void;
  setModalOpen: (setModalOpen: boolean) => void;
  setOpenMeaning: (setOpenMeaning: boolean) => void;
  setFromSearch: (setFromSearch: boolean) => void;
  getSelectedWord: (getSelectedWord: string) => void;
  setResultOpen: (setResultOpen: boolean) => void;
  meaningSelected: (getSelectedWord: string | undefined) => void;
  wordSelected: string;
  allListWords: {}[];
};

const Container = styled.div`
  display: flex;
  flex-direction: row;
  margin: 5px 16px 0px 16px;
  border-bottom: 1px solid #e5e5ea;
  padding-bottom: 16px;
`;
const TextResults = styled.span`
  font-size: 14px;
  min-width: max-content;
  color: ${({ theme }) => theme.colors.UIColorBlack};
  font-family: ${({ theme }) => theme.font.SF};
`;
const WordResult = styled.span`
  font-size: 14px;
  font-weight: bold;
  margin-left: 4px;
  color: ${({ theme }) => theme.colors.UIColorBlack};
  font-family: ${({ theme }) => theme.font.SF};
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
`;
const Box = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
  overflow-y: scroll;
  padding-bottom: 46px;
  padding-top: 5px;
`;
const ResultGlossary = ({
  setSearchOpen,
  setModalOpen,
  setOpenMeaning,
  getSelectedWord,
  setFromSearch,
  meaningSelected,
  allListWords,
  setResultOpen,
  wordSelected,
}: TypeSearch) => {
  const [currentWord, stateCurrentWord] = useState<string>(wordSelected);

  const openPredata = (text: string) => {
    stateCurrentWord(text);
  };

  useEffect(() => {
    const preWord = localStorage.getItem("ListResult");
    if (preWord !== null && preWord !== "") {
      openPredata(preWord);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentWord]);
  return (
    <Fragment>
      <BaseNav
        inFirstPage={true}
        currentPage={setResultOpen}
        title={text.label_text_results}
        goBack={setSearchOpen}
        showButton={false}
        setOpenModal={setModalOpen}
      />
      <Box>
        <Container>
          <TextResults>{text.label_see_result}</TextResults>
          <WordResult>{currentWord}</WordResult>
        </Container>
        <ListResults
          allListWords={allListWords}
          meaningSelected={meaningSelected}
          setFromSearch={setFromSearch}
          setCloseSearch={setSearchOpen}
          setOpenMeaning={setOpenMeaning}
          getSelectedWord={getSelectedWord}
          wordInRow={currentWord}
        />
      </Box>
    </Fragment>
  );
};
export default ResultGlossary;
