import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import 'fonts/DancingScript-Regular.ttf';
import 'fonts/NewYorkSmall-Regular.otf';
import 'fonts/SFProTextRegular.ttf';
import 'fonts/NewYorkSmall-Bold.otf';
import { ApolloProvider } from '@apollo/client/react';
import { ApolloClient, InMemoryCache } from '@apollo/client';
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom';
import Success from 'Page/Success';
import { ThemeProvider } from 'styled-components';
import theme from 'Theme/theme';
import ErrorLink from 'Page/ErrorLink';
import LinkExpire from 'Page/LinkExpire';
import Home from 'Page/Home';

const client = new ApolloClient({
  uri:
    process.env.REACT_APP_API_URL || 'https://weinkeller.herokuapp.com/graphql',
  cache: new InMemoryCache(),
});

ReactDOM.render(
  <React.StrictMode>
    <ApolloProvider client={client}>
      <ThemeProvider theme={theme}>
        <Router>
          <Switch>
            <Route exact path="/" component={Home} />
            <Route exact path="/:uuid" component={App} />
            <Route
              exact
              path="/:uuid/success/:star/:name"
              component={Success}
            />
            <Route exact path="/:uuid/error" component={ErrorLink} />
            <Route exact path="/:uuid/expire" component={LinkExpire} />
          </Switch>
        </Router>
      </ThemeProvider>
    </ApolloProvider>
  </React.StrictMode>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
