import ListWord from "Components/Glossary/ListWord/ListWords";
import React, { useRef } from "react";
import NavModalGlossary from "../../NavModalGlossary";
import Loader from "react-loader-spinner";
import { Virtuoso } from "react-virtuoso";
import getFirstIndexOfLetter from "utils/getFirstIndexOfLetter";
import getListWithSpecialLetter from "utils/getListData";
import findGroupData from "utils/findGroupData";
import findMeaing from "utils/findMeaing";
import {
  BoxList,
  Container,
  FakeTopicA,
  Load,
  TextA,
  Wrapper,
} from "./MainGlossary.styled";
import { useState } from "react";
import { useEffect } from "react";
import Words from "Data/Words.json";

type TypeMainGlossary = {
  setOpenModal: (setOpenModal: boolean) => void;
  infoOpen: (infoOpen: boolean) => void;
  setOpenSearch: (setOpenSearch: boolean) => void;
  meaningOpen: (meaningOpen: boolean) => void;
  setFromSearch: (setFromSearch: boolean) => void;
  setResultOpen: (setResultOpen: boolean) => void;
  wordSelected: (wordSelected: string) => void;
  meaningSelected: (meaningSelected: string | undefined) => void;
  setAllListWords: (meaningSelected: {}[]) => void;
  show: boolean;
};
type TypeData = {
  meaning: string;
  word: string;
}[];

const MainGlossary = ({
  setOpenModal,
  infoOpen,
  setOpenSearch,
  meaningOpen,
  wordSelected,
  setFromSearch,
  meaningSelected,
  setAllListWords,
  setResultOpen,
  show,
}: TypeMainGlossary) => {
  const newData: TypeData = Words;
  const [groupName, setGroupName] = useState<any>([]);
  const virtuoso = useRef(null);
  const newGroupData: any = getListWithSpecialLetter(groupName);
  const newFisrtLetter: any = findGroupData(groupName);
  const groupCounts = newGroupData?.map((eachName: any) => {
    return eachName.length;
  });
  const allData = [].concat.apply([], newGroupData);

  useEffect(() => {
    localStorage.setItem("ListResult", "");
    setAllListWords(newData);
    setGroupName(newData?.map((item: any) => item?.word));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [newData]);
  return (
    <Container show={show}>
      <NavModalGlossary
        setModalOpen={setOpenModal}
        setInfoOpen={infoOpen}
        groupCounts={groupCounts}
        groups={newFisrtLetter}
        setCurrentScroll={virtuoso}
        searchOpen={setOpenSearch}
        setResultOpen={setResultOpen}
      />
      <Wrapper>
        {newData === undefined ? (
          <Load>
            <Loader type="Oval" color="#EA1A20" width={100} height={100} />
          </Load>
        ) : (
          <BoxList>
            <Virtuoso
              ref={virtuoso}
              data={allData}
              itemContent={(index) => {
                return (
                  <>
                    {index ===
                      getFirstIndexOfLetter(index, groupCounts)
                        ?.overviewIndex && (
                      <FakeTopicA>
                        <TextA top={Boolean(index === 0)}>
                          {
                            newFisrtLetter[
                              getFirstIndexOfLetter(index, groupCounts)
                                ?.firstLetterIndex
                            ]
                          }
                        </TextA>
                      </FakeTopicA>
                    )}
                    <ListWord
                      setFromSearch={setFromSearch}
                      fromSearch={false}
                      disableDescribe={true}
                      setCloseSearch={setOpenSearch}
                      key={allData[index]}
                      wordInRow={allData[index]}
                      iconRed={false}
                      describe={findMeaing(newData, allData[index])}
                      meaningSelected={meaningSelected}
                      setOpenMeaning={meaningOpen}
                      getSelectedWord={wordSelected}
                    />
                  </>
                );
              }}
            />
          </BoxList>
        )}
      </Wrapper>
    </Container>
  );
};
export default MainGlossary;
