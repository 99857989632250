import React from "react";
import styled from "styled-components";
import Months from "Data/Months.json";
const Container = styled.div`
  display: flex;
  font-size: 12px;
  font-family: ${({ theme }) => theme.font.SF};
  color: ${({ theme }) => theme.colors.UIColorGray};
  @media screen and (min-width: ${({ theme }) => theme.breakpoints.md}) {
    font-size: 14px;
  }
`;
const CurrentDate = () => {
  var months = Months;
  var today = new Date(),
    curMonth = months[today.getMonth()],
    curYear = today.getFullYear(),
    curDate = today.getDate();
  const date = curMonth + " " + curDate + ", " + curYear;

  return <Container>{date}</Container>;
};
export default CurrentDate;
