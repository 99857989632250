import React from "react";
import styled from "styled-components";
import text from "Data/Text";
import Lottie from "react-lottie";
import Anime from "Asset/weinkeller_button_loading.json";

const ButtonSubmit = styled.button`
  background: linear-gradient(90deg, #ea1a20 0%, #ff0007 100%);
  height: 56px;
  border-radius: 8px;
  border: transparent solid 0px;
  margin: 0px auto 29px auto;
  width: 342px;
  display: block;
  @media screen and (max-width: 374px) {
    width: 288px;
  }
  @media screen and (min-width: ${({ theme }) => theme.breakpoints.md}) {
    margin: 0px auto 32px 0px;
  }
`;
const LabelButton = styled.span`
  font-weight: bold;
  font-size: 18px;
  color: ${({ theme }) => theme.colors.White};
  font-family: ${({ theme }) => theme.font.NewYork};
`;
type TypeLoad = {
  loadSubmit: boolean;
  setConfirmOpen: (setConfirmOpen: boolean) => void;
  error: boolean;
};
const BaseButton = ({ loadSubmit, setConfirmOpen, error }: TypeLoad) => {
  const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: Anime,
    rendererSettings: {
      preserveAspectRatio: "xMidYMid slice",
    },
  };
  const onclick = () => {
    error ? setConfirmOpen(false) : setConfirmOpen(true);
  };
  return (
    <ButtonSubmit type="submit" onClick={onclick} className="submit-button">
      <LabelButton>
        {loadSubmit ? (
          <Lottie options={defaultOptions} height={16} width={40} />
        ) : (
          text.buttonSubmit
        )}
      </LabelButton>
    </ButtonSubmit>
  );
};
export default BaseButton;
