import { FC, ReactNode, useEffect } from "react";
import styled from "styled-components";
const Wrapper = styled.div`
display: block;`

const BoxWrapScroll: FC<{ children?: ReactNode }> = ({ children }) => {
  useEffect(() => {
    window.scrollTo({ top: 0 });
    window.onbeforeunload = function () {
      window.scrollTo({ top: 0 });
    };
  }, []);
  return <Wrapper>{children}</Wrapper>;
};
export default BoxWrapScroll;
