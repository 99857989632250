import React, { useEffect } from "react";
import InputLabel from "@material-ui/core/InputLabel";
import { useState } from "react";
import BaseButton from "Components/Button/Button";
import StarIcon from "@material-ui/icons/Star";
import ReactStars from "react-rating-stars-component";
import CurrentDate from "./CurrentDate";
import {
  Form,
  Group,
  Label,
  InputName,
  InputText,
  Warning,
} from "./Comments.styled";
import text from "Data/Text";

const thirdExample = {
  size: 25,
  count: 5,
  value: 0,
  color: "#C7C7CC",
  emptyIcon: <StarIcon fontSize="large" />,
  filledIcon: <StarIcon fontSize="large" />,
  activeColor: "#FF453A",
  classNames: "star",
};
type TypeComments = {
  dataComments: (dataComments: object) => void;
  setConfirmOpen: (setConfirmOpen: boolean) => void;
  loadSubmit: boolean;
};
const Comments = ({
  dataComments,
  setConfirmOpen,
  loadSubmit,
}: TypeComments) => {
  const [nature, setNature] = useState("");
  const [nose, setNose] = useState("");
  const [taste, setTaste] = useState("");
  const [leaving, setLeaving] = useState("");
  const [overall, setOverall] = useState("");
  const [rate, setRate] = useState(0);
  const [warningStar, setWarningStar] = useState(false);
  const [warningName, setWarningName] = useState(false);
  const [name, setName] = useState("");
  const [error, setError] = useState(true);
  const ratingChanged = (newRating: number) => {
    setRate(newRating);
    setWarningStar(false);
  };
  const send = () => {
    dataComments({
      name: name,
      leaving: leaving,
      nature: nature,
      nose: nose,
      overall: overall,
      taste: taste,
      star: rate,
    });
    setError(false);
  };

  const addDada = (event: { preventDefault: () => void }) => {
    event.preventDefault();
    rate < 1 ? setWarningStar(true) : setWarningStar(false);
    name.trim().length === 0 ? setWarningName(true) : setWarningName(false);
  };

  useEffect(() => {
    rate < 1 || name.trim().length === 0 || warningName || warningStar
      ? setConfirmOpen(false)
      : send();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [warningName, warningStar, rate, name,leaving,nature,nose,overall,taste]);

  return (
    <div>
      <Form noValidate autoComplete="off" onSubmit={addDada}>
        <CurrentDate />
        <InputName
          id="name"
          placeholder="Your Name"
          onChange={(event) => {
            setName(event.target.value);
            setWarningName(false);
          }}
        />
        {warningName ? (
          <Warning type={"errorName"}>{text.warningName}</Warning>
        ) : (
          <></>
        )}
        <div style={{ marginTop: "16px", zIndex: 0 }}>
          <ReactStars {...thirdExample} onChange={ratingChanged} />
          {warningStar ? (
            <Warning type={"errorStar"}>{text.warningStar}</Warning>
          ) : (
            <></>
          )}
        </div>
        <Group key={"Box"}>
          <InputLabel htmlFor="input-with-icon-adornment">
            <Label> {text.nature}</Label>
          </InputLabel>
          <InputText
            last={"f"}
            className="input-comment"
            minRows={1}
            value={nature}
            placeholder="Write Something"
            onChange={(event) => setNature(event.target.value)}
          />
          <InputLabel htmlFor="input-with-icon-adornment">
            <Label> {text.nose}</Label>
          </InputLabel>
          <InputText
            last={"f"}
            className="input-comment"
            minRows={1}
            value={nose}
            placeholder="Write Something"
            onChange={(event) => setNose(event.target.value)}
          />
          <InputLabel htmlFor="input-with-icon-adornment">
            <Label> {text.taste}</Label>
          </InputLabel>
          <InputText
            last={"f"}
            minRows={1}
            value={taste}
            placeholder="Write Something"
            onChange={(event) => setTaste(event.target.value)}
          />
          <InputLabel htmlFor="input-with-icon-adornment">
            <Label> {text.leaving}</Label>
          </InputLabel>
          <InputText
            last={"f"}
            minRows={1}
            value={leaving}
            placeholder="Write Something"
            onChange={(event) => setLeaving(event.target.value)}
          />
          <InputLabel htmlFor="input-with-icon-adornment">
            <Label>{text.overall}</Label>
          </InputLabel>
          <InputText
            minRows={1}
            style={{ borderBottom: "unset", marginBottom: "unset" }}
            value={overall}
            placeholder="Write Something"
            onChange={(event) => setOverall(event.target.value)}
            last={"t"}
          />
          <BaseButton
            loadSubmit={loadSubmit}
            setConfirmOpen={setConfirmOpen}
            error={error}
          />
        </Group>
      </Form>
    </div>
  );
};
export default Comments;
