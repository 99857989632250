import text from "Data/Text";
import React from "react";
import styled from "styled-components";
import icon_book from "Asset/icon_book.svg";

const Container = styled.div`
  display: flex;
  background: #fde8e9;
  position: sticky;
  top: 0px;
  left: 0px;
  right: 0px;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.05);
  min-height: 57px;
  width: 100%;
  justify-content: center;
  z-index: 5 !important;
`;
const TextExpain = styled.span`
  font-size: 14px;
  color: ${({ theme }) => theme.colors.UIColorBlack};
  font-family: ${({ theme }) => theme.font.SF};
  font-weight: normal;
  width: 173px;
  margin: auto 16px auto 16px;
  @media screen and (min-width: ${({ theme }) => theme.breakpoints.md}) {
    margin: auto 40px auto 0px;
    width: auto;
  }
`;
const ButtonGlossary = styled.button`
  border-radius: 8px;
  height: 40px !important;
  width: 154px;
  margin-right: 16px;
  align-self: center;
  border: none;
  background-color: ${({ theme }) => theme.colors.ButtonRed};
  display: flex;
  padding: unset;
  align-items: center;
  justify-content: space-between;
`;
const IconBook = styled.img`
  width: 18px;
  height: 20px;
  margin-left: 11px;
  justify-self: center;
`;
const TextButtonGlossary = styled.span`
  color: ${({ theme }) => theme.colors.White};
  font-weight: bold;
  font-size: 14px;
  font-family: ${({ theme }) => theme.font.NewYorkBold};
  margin-right: 8px;
`;

type TYpeNavGlossary = {
  setOpenModal: (setOpenModal: boolean) => void;
};
const NavGlossary = ({ setOpenModal }: TYpeNavGlossary) => {
  const openModalGlossary = () => {
    setOpenModal(true);
  };
  return (
    <Container>
      <TextExpain>{text.label_expain}</TextExpain>
      <ButtonGlossary onClick={openModalGlossary}>
        <IconBook src={icon_book} />
        <TextButtonGlossary>{text.label_button_wine}</TextButtonGlossary>
      </ButtonGlossary>
    </Container>
  );
};
export default NavGlossary;
