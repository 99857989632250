import React from "react";
import BaseNave from "Components/Glossary/BaseNav/BaseNav";
import { useState } from "react";
import text from "Data/Text";
import icon_plus from "Asset/plusIcon.svg";
import icon_done from "Asset/done_24px.svg";
import {
  BoxMeaning,
  BoxWord,
  ButtonCopy,
  Container,
  Icon,
  Word,
  WordCopy,
} from "./Meaning.styled";
import ModalCopy from "Components/Glossary/ModalCopy/ModalCopy";
import { CopyToClipboard } from "react-copy-to-clipboard";
import { BoxDetails } from "Components/WineDetails/WineDetails.styled";
import { useEffect } from "react";

type TypeMeaning = {
  setMeaningOpen: (setMeaningOpen: boolean) => void;
  setModalOpen: (setModalOpen: boolean) => void;
  setResultOpen: (setResultOpen: boolean) => void;
  wordSelected: string;
  describe: string | undefined;
  fromSearch: boolean;
  inFirstPage: boolean;
};
const Meaning = ({
  setMeaningOpen,
  setModalOpen,
  setResultOpen,
  wordSelected,
  describe,
  fromSearch,
  inFirstPage,
}: TypeMeaning) => {
  const [copy, setCopy] = useState(text.label_copy);
  const [clickIcon, setClickIcon] = useState(false);
  const [modalCopy, setModalCopy] = useState(false);
  const str: string = wordSelected ? wordSelected : "";
  const onHandleClick = () => {
    setClickIcon(true);
    setCopy(text.label_copied);
    setModalCopy(true);
  };
  useEffect(() => {
    setResultOpen(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  return (
    <Container>
      <BaseNave
        title={""}
        showButton={true}
        currentPage={setMeaningOpen}
        goBack={setResultOpen}
        setOpenModal={setModalOpen}
        inFirstPage={fromSearch}
      />
      <BoxDetails>
        <BoxWord>
          <CopyToClipboard text={str}>
            <ButtonCopy onClick={onHandleClick} click={clickIcon}>
              <Icon
                src={clickIcon ? icon_done : icon_plus}
                copy={clickIcon}
              ></Icon>
              <WordCopy col={clickIcon}>{copy}</WordCopy>
            </ButtonCopy>
          </CopyToClipboard>
          <Word>{wordSelected}</Word>
        </BoxWord>
        <BoxMeaning>{describe}</BoxMeaning>
      </BoxDetails>
      {modalCopy && <ModalCopy word={wordSelected} />}
    </Container>
  );
};
export default Meaning;
