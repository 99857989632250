import ImageDafault from 'Asset/Default.svg';
import {
  Container,
  LabelHead,
  Details,
  ImageWine,
  GroupData,
  NameWine,
  YearWine,
  TypeWine,
  TasteTopic,
  TasteWine,
  ModalLoading,
} from './WineDetails.styled';
import text from 'Data/Text';
import { useEffect } from 'react';
import PulseLoader from 'react-spinners/PulseLoader';

type TypeWineDetails = {
  dataWine: {
    name: string;
    year: string;
    color: string;
    taste: string;
    image_url: string;
    _id: string;
  };
  setNameWine: (setNameWine: string) => void;
  setIdWine: (setIdWine: string) => void;
};

const WineDetails = ({ dataWine, setNameWine, setIdWine }: TypeWineDetails) => {
  const nameWine = dataWine?.name;
  const _id = dataWine?._id;
  const year = dataWine?.year;
  const color = dataWine?.color;
  const taste = dataWine?.taste;
  const image = dataWine?.image_url ? dataWine?.image_url : ImageDafault;

  useEffect(() => {
    setNameWine(nameWine);
    setIdWine(_id);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [nameWine]);

  return (
    <>
      {nameWine === undefined ? (
        <ModalLoading>
          <PulseLoader color={'#EA1A20'} loading={true} size={16} />
        </ModalLoading>
      ) : (
        <Container>
          <LabelHead>{text.label_review}</LabelHead>
          <Details>
            <ImageWine src={image} />
            <GroupData>
              <NameWine>{nameWine}</NameWine>
              <YearWine>{year}</YearWine>
              <TasteTopic hide={taste ? false : true}>
                {text.label_taste}
              </TasteTopic>
              <TasteWine show={taste ? false : true}>{taste}</TasteWine>
              <TypeWine>{color}</TypeWine>
            </GroupData>
          </Details>
        </Container>
      )}
    </>
  );
};

export default WineDetails;
