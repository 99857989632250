import React from "react";
import { useEffect } from "react";
import { useState } from "react";
import styled from "styled-components";
import InfoGlossary from "./Details/InfoGlossary";
import MainGlossary from "./Details/MainGlossary/MainGlossary";
import ResultGlossary from "./Details/ResultGlossary/ResultGlossary";
import SearchGlossary from "./Details/SearchGlossary";
import Meaning from "./Meaning/Meaning";

const Container = styled.div`
  background: rgba(28, 28, 30, 0.6);
  position: fixed;
  top: 0px;
  left: 0px;
  width: 100%;
  height: 100%;
  display: flex;
  z-index: 10;
  #root {
    overflow: hidden;
  }
`;

const BoxModal = styled.div`
  border-radius: 32px 32px 0px 0px;
  width: 100%;
  z-index: 100;
  background-color: white;
  flex-direction: column;
  margin-top: 16px;
  display: flex;
  height: auto;
  overflow: hidden;
  @media screen and (min-width: ${({ theme }) => theme.breakpoints.md}) {
    width: 375px;
    height: 820px;
    position: unset;
    margin: auto auto;
    border-radius: 32px;
  }
  @media (min-width: ${({ theme }) =>
      theme.breakpoints.md}) and (max-height: 830px) {
    height: 88%;
    margin-top: auto;
    margin-bottom: auto;
    border-radius: 32px;
  }
`;
type TypeModalGlossary = {
  modalOpen: (modalOpen: boolean) => void;
};
const ModalGlossary = ({ modalOpen }: TypeModalGlossary) => {
  const [openInfo, setOpenInfo] = useState(false);
  const [openSearch, setOpenSearch] = useState(false);
  const [openMeaning, setOpenMeaning] = useState(false);
  const [openResult, setResultOpen] = useState(false);
  const [fromSearch, setFromSearch] = useState(false);
  const [wordTypeing, setWordTypeing] = useState<string>("");
  const [word, setWord] = useState<string>("");
  const [allListWords, setAllListWords] = useState<{}[]>([]);
  const [meaning, setMeaning] = useState<string | undefined>();
  useEffect(() => {
    if (openSearch) {
      setWordTypeing("");
    }
  }, [openResult, openInfo, openSearch, openMeaning, word]);
  return (
    <Container>
      <BoxModal>
        <MainGlossary
          show={
            openInfo === false &&
            openSearch === false &&
            openMeaning === false &&
            openResult === false
          }
          setFromSearch={setFromSearch}
          setOpenModal={modalOpen}
          infoOpen={setOpenInfo}
          setOpenSearch={setOpenSearch}
          meaningOpen={setOpenMeaning}
          wordSelected={setWord}
          meaningSelected={setMeaning}
          setAllListWords={setAllListWords}
          setResultOpen={setResultOpen}
        />
        {openInfo && (
          <InfoGlossary infoStateOpen={setOpenInfo} setOpenModal={modalOpen} />
        )}
        {openSearch && (
          <SearchGlossary
            setResultOpen={setResultOpen}
            setModalOpen={modalOpen}
            setWordTypeing={setWordTypeing}
            setOpenSearch={setOpenSearch}
            word={wordTypeing}
          />
        )}
        {openMeaning && (
          <Meaning
            inFirstPage={true}
            describe={meaning}
            fromSearch={fromSearch}
            setResultOpen={setResultOpen}
            setMeaningOpen={setOpenMeaning}
            setModalOpen={modalOpen}
            wordSelected={word}
          />
        )}
        {openResult && (
          <ResultGlossary
            allListWords={allListWords}
            wordSelected={wordTypeing}
            setResultOpen={setResultOpen}
            meaningSelected={setMeaning}
            setFromSearch={setFromSearch}
            setSearchOpen={setOpenSearch}
            setModalOpen={modalOpen}
            setOpenMeaning={setOpenMeaning}
            getSelectedWord={setWord}
          />
        )}
      </BoxModal>
    </Container>
  );
};
export default ModalGlossary;
