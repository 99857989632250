import styled from "styled-components";

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: 100vw;
  height: 100%;
`;
export const Box = styled.div<{ src: string }>`
  height: 100vh;
  width: 100%;
  display: flex;
  background-image: url(${({ src }) => src});
  background-repeat: no-repeat;
  background-position: top right;
`;
export const Wrapper = styled.div`
  display: inline-block;
  height: 100%;
  @media screen and (min-width: ${({ theme }) => theme.breakpoints.md}) {
    display: flex;
    flex-direction: column;
  }
`;
export const Group = styled.div<{ show: boolean }>`
  display: ${({ show }) => (show ? "none" : "flex")};
  flex-direction: column;
  height: 100%;
  width: 100%;
  @media screen and (min-width: ${({ theme }) => theme.breakpoints.md}) {
    display: flex;
    flex-direction: row;
    padding-top: 64px;
    justify-content: center;
  }
  @media screen and (min-width: ${({ theme }) => theme.breakpoints.lg}) {
    padding-top: 40px;
  }
  @media screen and (min-width: ${({ theme }) => theme.breakpoints["2xl"]}) {
    max-width: unset;
  }
  @media screen and (min-height: 901px) {
    margin: auto auto;
    max-width: unset;
  }
`;
