const text = {
    //Button
    buttonSubmit: 'Submit',
    //Comments
    warningName: 'Please enter your name.',
    warningStar: 'Please give this wine at least a star.',
    nature: 'Nature',
    nose: 'Nose',
    taste: 'Taste',
    leaving: 'Leaving',
    overall: 'Overall Assessment',
    //Details of Wine
    label_review: 'Write a review',
    label_taste: "Taste of Wine",
    text_taste: "Aalto PS taste carries herbal or grassy scents. In the last, smoky-sweet finish.",
    //Nav Glossary
    label_expain: 'Discover terms to use for wine tasting.',
    label_button_wine: 'Wine Glossary',
    //Nav Modal Glossary
    label_title_wine: 'Wine Glossary',
    label_close: 'Close',
    //Search Glossary
    label_search: 'Search for words, definitions',
    //Info Glossary
    label_title_how_to_use: 'How to Use',
    label_details_search: 'Find your right word by search.',
    label_details_copy: 'On the word meaning page, Select Copy.',
    label_details_close: 'When ready to move on, Select Close to use selecting word on your decision.',
    //Search Glossary
    label_title_search: 'Search',
    label_see_result: 'See results for',
    label_text_results: 'Search Results',
    label_text_no_results: 'No Results Found ',
    label_text_try_again: 'Try another search. ',
    //Meanung
    label_copy: 'Copy',
    label_copied: 'Copied',
    //Confirm
    label_title_confirm: 'Confirm Submit',
    describe_confirm: 'You should submit only one review per wine. Are you sure you want to submit this review as ',
    label_button_submit: 'Submit',
    label_button_cancle: 'Cancel',
    //Link Expire
    label_title_expire: "Link Expired",
    label_expire_details: "Your Weinkeller link has expired. Now is the time for reviewing, so don't hesitate to contact the wine cellar owner to generate your new URL.",
    //Success
    label_title_thank: "Thank you",
    label_expire_review_of: "Your review of",
    label_expire_review_details: "in Mayr's wine cellar has been submitted.",
    //Error Page
    label_title_error: "Review Not Found",
    label_error_details: "We're sorry — we can't find the page you're looking for. You can try retyping the URL or tell the wine cellar owner to fix the broken link."
    

    
}
export default text