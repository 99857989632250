import styled from "styled-components";

export const Container = styled.div`
  display: flex;
  flex-direction: row;
  position: sticky;
  top: 0px;
  align-items: center;
  padding: 24px 16px 19px 0px;
  border-radius: 32px 32px 0px 0px;
  background-color: ${({ theme }) => theme.colors.White};
`;
export const IconBack = styled.img`
  width: 14px;
  height: 12px;
  padding-left: 21px;
`;
export const IconBackButton = styled.button`
  width: 20%;
  height: 100%;
  display: flex;
  justify-content: start;
  align-items: center;
  border: none;
  background-color: white;
  padding: unset;
`;
export const TextTitle = styled.span`
  font-size: 24px;
  color: ${({ theme }) => theme.colors.UIColorDarkGray};
  font-family: ${({ theme }) => theme.font.NewYork};
  font-weight: normal;
  text-align: center;
  width: 100%;
  padding-right: 13%;
`;
export const ButtonClose = styled.button<{ show: boolean }>`
  min-width: 72px;
  height: 40px;
  background-color: ${({ theme }) => theme.colors.ButtonRed};
  border: none;
  border-radius: 8px;
  display: ${({ show }) => (show ? "flex" : "none")};
  justify-content: center;
`;
export const TextClose = styled.span`
  font-size: 14px;
  color: ${({ theme }) => theme.colors.UIColorDarkGray};
  font-family: ${({ theme }) => theme.font.NewYork};
  font-weight: bold;
  color: ${({ theme }) => theme.colors.White};
  align-self: center;
`;
