const getFirstIndexOfLetter = (targetIndex: number, array: number[]) => {
  const maxlengthValue = array?.reduce(
    (previous, current, index) => {
      if (previous?.value >= targetIndex) {
        return previous;
      } else {
        return { value: previous?.value + current, index };
      }
    },
    { value: 0, index: 0 }
  );

  let start = 0;

  for (let index = 0; index < array.length; index++) {
    if (start >= targetIndex) {
      return {
        firstLetterIndex: index,
        overviewIndex: start,
      };
    } else {
      start += array[index];
    }
  }

  return {
    firstLetterIndex: maxlengthValue?.index,
    overviewIndex: Math.max(
      0,
      maxlengthValue?.value - array[maxlengthValue?.index]
    ),
  };
};
export default getFirstIndexOfLetter;
