import styled from "styled-components";

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 8px;
  height: 100%;
`;
export const Container = styled.div<{ show: boolean }>`
  display: ${({ show }) => (show ? "flex" : "none")};
  flex-direction: column;
  height: 100%;
`;
export const Load = styled.div`
  display: flex;
  margin: auto auto;
  height: 100%;
  align-items: center;
`;

export const BoxList = styled.div`
  display: block;
  height: 100%;
  margin-bottom: 15px;
`;

export const FakeTopicA = styled.div`
  display: block;
`;
export const TextA = styled.span<{ top: boolean }>`
  display: flex;
  align-items: start;
  padding-left: 16px;
  padding-top: ${({ top }) => (top ? "16px" : "32px")};
  margin-bottom: 16px;
  font-family: ${({ theme }) => theme.font.NewYorkBold};
  font-size: 18px;
  font-weight: bold;
  color: ${({ theme }) => theme.colors.UIColorBlack};
`;
