import React from "react";
import { useState } from "react";
import styled from "styled-components";
const Container = styled.div`
  width: 95%;
  display: flex;
  flex-direction: row;
  padding-left: 16px;
  -ms-overflow-style: none; /* for Internet Explorer, Edge */
  scrollbar-width: none; /* for Firefox */
  height: 32px;
  overflow: scroll;
  &::-webkit-scrollbar {
    display: none; /* for Chrome, Safari, and Opera */
  }
`;
const Circle = styled.div<{ bgClick: boolean }>`
  min-width: 32px;
  height: 32px;
  border-radius: 50%;
  margin-right: 8px;
  display: flex;
  justify-content: center;
  align-items: center;

  background-color: ${({ theme, bgClick }) =>
    bgClick ? theme.colors.LightRed3 : theme.colors.UIColorLightgray1};
`;
const Text = styled.span<{ letterClick: boolean }>`
  font-size: 14px;
  color: ${({ theme, letterClick }) =>
    letterClick ? theme.colors.RedWine2 : theme.colors.UIColorDarkGray};
  font-family: ${({ theme }) => theme.font.NewYorkBold};
  font-weight: bold;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
`;
type TypeLetter = {
  groupCounts: any[];
  groups: any[];
  setCurrentScroll: any;
};
const Letter = ({ groupCounts, groups, setCurrentScroll }: TypeLetter) => {
  const [clickIndex, setclickIndex] = useState(100);
  const SelectedGroup = (e: any, itemIndex: any, index: number) => {
    e.preventDefault();
    setCurrentScroll.current.scrollToIndex({
      index: itemIndex,
    });
    setclickIndex(index);
  };

  return (
    <Container>
      {groupCounts
        ?.reduce(
          (acc: any, count: any) => {
            return {
              firstItemsIndexes: [...acc.firstItemsIndexes, acc.offset],
              offset: acc.offset + count,
            };
          },
          { firstItemsIndexes: [], offset: 0 }
        )
        .firstItemsIndexes.map((itemIndex: any, index: any) => (
          <Circle
            key={index}
            bgClick={index === clickIndex}
            className={index.toString() + itemIndex.toString()}
            onClick={(event) => SelectedGroup(event, itemIndex, index)}
          >
            <Text
              className={itemIndex.toString()}
              letterClick={index === clickIndex}
            >
              {groups[index].toUpperCase()}
            </Text>
          </Circle>
        ))}
    </Container>
  );
};
export default Letter;
