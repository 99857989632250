import { useState } from "react";
import Comments from "./Components/Reviewer/Comments";
import WineDetails from "./Components/WineDetails/WineDetails";
import Background from "Asset/Illustration.svg";
import { useMutation } from "@apollo/client";
import { useEffect } from "react";
import GetData from "Data/GetData";
import { useHistory, useParams, withRouter } from "react-router-dom";
import UpsertGuestReviewa from "Data/DetailsMutation";
import { Box, Container, Wrapper, Group } from "App.styled";
import NavGlossary from "Components/Glossary/NavGlossary/NavGlossary";
import ModalGlossary from "Components/Glossary/ModalGlossary/ModalGlossary";
import BoxWrapScroll from "Components/Layout";
import Confirm from "Components/Confirm/Confirm";

const App = () => {
  const [allData, setData] = useState<any>({});
  const [sendData, setSendData] = useState(false);
  const [nameWine, setNameWine] = useState("");
  const [openGlossary, setGlossaryOpen] = useState(false);
  const [openConfirm, setConfirmOpen] = useState(false);
  const history = useHistory();
  const [id, setId] = useState("");
  const { uuid }: { uuid: string } = useParams();
  const [addUpsertGuestReviewa, { loading }] = useMutation(UpsertGuestReviewa, {
    onCompleted: () => {
      history.push(`/${uuid}/success/${allData.star}/${nameWine}`);
    },
  });

  const add = () => {
    addUpsertGuestReviewa({
      variables: {
        nature: allData?.nature,
        nose: allData?.nose,
        taste: allData?.taste,
        leaving: allData?.leaving,
        overall: allData?.overall,
        reviewer: allData?.name,
        star: allData?.star,
        id: id,
        uuid: uuid,
      },
    });
  };

  useEffect(() => {
    openGlossary || openConfirm
      ? (document.body.style.position = "fixed")
      : (document.body.style.position = "unset");
    sendData ? add() : setSendData(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [sendData, openGlossary, openConfirm]);

  return (
    <BoxWrapScroll>
      <Box src={Background} className="scroll-box">
        <Container>
          <Wrapper>
            <NavGlossary setOpenModal={setGlossaryOpen} />
            {openGlossary && <ModalGlossary modalOpen={setGlossaryOpen} />}
            <Group show={openGlossary}>
              <WineDetails
                dataWine={GetData(uuid)}
                setNameWine={setNameWine}
                setIdWine={setId}
              />
              <Comments
                dataComments={setData}
                setConfirmOpen={setConfirmOpen}
                loadSubmit={loading}
              />
              {openConfirm && (
                <Confirm
                  nameReviewer={allData?.name}
                  setConfirmButton={setSendData}
                  setConfirmOpen={setConfirmOpen}
                />
              )}
            </Group>
          </Wrapper>
        </Container>
      </Box>
    </BoxWrapScroll>
  );
};

export default withRouter(App);
