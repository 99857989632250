import styled from "styled-components";
import TextareaAutosize from "react-textarea-autosize";
export const Form = styled.form`
  display: flex;
  flex-direction: column;
  margin-top: 24px;
  margin-left: 16px;
  margin-right: 16px;
  &.star:focus {
    border-color: unset;
    outline: none;
  }
  @media screen and (min-width: ${({ theme }) => theme.breakpoints.md}) {
    margin-top: 40px;
    margin-left: 64px;
    width: max-content;
  }
  @media screen and (min-width: ${({ theme }) => theme.breakpoints.lg}) {
    margin-left: 153px;
  }
`;
export const Group = styled.div`
  display: flex;
  flex-direction: column;
  text-align: start;
  background: transparent !important;
  margin-top: 7px;
  @media screen and (min-width: ${({ theme }) => theme.breakpoints.md}) {
    width: 372px;
    margin-top: 23px;
  }
`;
export const Label = styled.span`
  font-size: 14px;
  color: ${({ theme }) => theme.colors.UIColorBlack};
  font-family: ${({ theme }) => theme.font.NewYork};
  font-weight: bold;
`;

export const InputName = styled.input`
  font-family: ${({ theme }) => theme.font.NewYork};
  font-size: 24px;
  border: none;
  margin-top: 5px;
  resize: none;
  outline: none;
  height: 29px;
  caret-color: #c42d31 !important;
  background: transparent;
  padding: unset;
  border-radius: none;
  margin-left: -1px;
`;

export const Warning = styled.span<{ type: string }>`
  width: 100%;
  color: ${({ theme }) => theme.colors.Error};
  font-size: 12px;
  font-family: ${({ theme }) => theme.font.SF};
  margin-top: ${({ type }) => (type === "errorName" ? "8px" : "16px")};
`;

export const InputText = styled(TextareaAutosize)<{ last: string }>`
  border: none;
  display: block;
  margin-top: 8px;
  font-size: 16px;
  line-height: 19px;
  font-family: ${({ theme }) => theme.font.SF};
  resize: none;
  outline: none;
  caret-color: #c42d31 !important;
  border-bottom: #e5e5ea 1px solid;
  margin-bottom: 16px;
  padding: unset;
  color: ${({ theme }) => theme.colors.UIColorBlack};
  border-radius: unset;
  padding-bottom: 24px;
  margin-left: -1px;
  @media screen and (min-width: ${({ theme }) => theme.breakpoints.md}) {
    padding-bottom: ${({ last }) => (last === "t" ? "56px" : "24px")};
  }
`;
