import styled from "styled-components";

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
`;
export const BoxWord = styled.div`
  display: flex;
  flex-direction: column;
  height: auto;
  align-items: center;
  margin: 24px 16px 0px 16px;
`;
export const Word = styled.span`
  display: flex;
  width: 100%;
  height: fit-content;
  font-size: 13px;
  align-self: start;
  color: ${({ theme }) => theme.colors.UIColorDarkGray};
  font-family: ${({ theme }) => theme.font.NewYork};
  font-weight: bold;
  margin-top: 16px;
  @media screen and (min-width: ${({ theme }) => theme.breakpoints.md}) {
    font-size: 15px;
  }
`;
export const ButtonCopy = styled.button<{ click: boolean }>`
  display: flex;
  flex-direction: row;
  width: 101px;
  height: 40px;
  background-color: ${({ theme, click }) =>
    click ? theme.colors.SuccessLight : theme.colors.White};
  border: 2px solid
    ${({ theme, click }) =>
      click ? theme.colors.SuccessLight : theme.colors.ButtonRed};
  box-sizing: border-box;
  border-radius: 8px;
  padding: unset;

  align-self: flex-end;
`;
export const WordCopy = styled.span<{ col: boolean }>`
  align-self: center;
  font-size: 14px;
  color: ${({ theme, col }) =>
    col ? theme.colors.Success : theme.colors.ButtonRed};
  font-family: ${({ theme }) => theme.font.NewYorkBold};
  font-weight: bold;

`;
export const Icon = styled.img<{ copy: boolean }>`
  align-self: center;
  margin-left: ${({ copy }) => (copy ? "12px" : "19px")};
  margin-right: ${({ copy }) => (copy ? "11px" : "12px")};

`;
export const BoxMeaning = styled.span`
  font-size: 14px;
  color: ${({ theme }) => theme.colors.Black};
  font-family: ${({ theme }) => theme.font.SF};
  font-weight: normal;
  margin: 16px;
  line-height: 17px;
`;
