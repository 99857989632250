import { gql } from "@apollo/client";
const UpsertGuestReviewa = gql`
  mutation addReview(
    $nature: String
    $nose: String
    $taste: String
    $leaving: String
    $overall: String
    $reviewer: String!
    $star: Int!
    $id: String!
    $uuid: String!
  ) {
    upsertGuestReview(
      uuid: $uuid
      review: {
        wine_id: $id
        reviewer: $reviewer
        star: $star
        comment: {
          nature: $nature
          leaving: $leaving
          taste: $taste
          nose: $nose
          overall: $overall
        }
      }
    ) {
      _id
      wine_id
    }
  }
`;
export default UpsertGuestReviewa;
