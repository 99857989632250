import styled from "styled-components";
export const Image = styled.img`
  width: 377px;
  height: 402px;
  margin-left: 18.67%;
  overflow: hidden;
  @media screen and (min-width: ${({ theme }) => theme.breakpoints.sm}) {
    margin-left: 30%;
  }
  @media screen and (min-width: ${({ theme }) => theme.breakpoints.md}) {
    width: 450px;
    height: 480px;
    margin-left: 50%;
  }
  @media screen and (min-width: ${({ theme }) => theme.breakpoints.lg}) {
    width: 450px;
    height: 480px;
    margin-left: 50%;
  }
`;
export const Container = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  overflow: hidden !important;
`;
export const TextDetails = styled.span`
  display: flex;
  flex-direction: column;
  margin-left: 6.4%;
  margin-top: 48px;
  width: fit-content;
  @media screen and (min-width: ${({ theme }) => theme.breakpoints.md}) {
    margin-left: 8.34%;
    margin-top: 80px;
  }
  @media screen and (min-width: ${({ theme }) => theme.breakpoints.lg}) {
    margin-left: 22.78%;
    margin-top: 80px;
  }
`;
export const Topic = styled.span`
  font-family: ${({ theme }) => theme.font.NewYork};
  font-size: 24px;
  @media screen and (min-width: ${({ theme }) => theme.breakpoints.md}) {
    font-size: 40px;
  }
`;

export const Details = styled.span`
  margin-top: 24px;
  width: 311px;
  font-family: ${({ theme }) => theme.font.SF};
  font-size: 14px;
  @media screen and (min-width: ${({ theme }) => theme.breakpoints.md}) {
    width: 400px;
    font-size: 16px;
  }
`;
