import BaseNav from "Components/Glossary/BaseNav/BaseNav";
import React from "react";
import how_to_search from "Asset/how_to_search.svg";
import how_to_copy from "Asset/how_to_copy.svg";
import how_to_close from "Asset/how_to_close.svg";
import styled from "styled-components";
import text from "Data/Text";

const Container = styled.div`
  display: flex;
  flex-direction: column;
  overflow: auto;
  min-height: 100%;
  height: max-content;
`;
const Box = styled.div`
  display: flex;
  flex-direction: column;
  margin: 16px 16px 11px 16px;
  justify-content: center;
  height: max-content;
`;
const Text = styled.span<{ first: boolean }>`
  font-size: 14px;
  color: ${({ theme }) => theme.colors.Black};
  font-family: ${({ theme }) => theme.font.SF};
  font-weight: normal;
  text-align: center;
  margin-top: ${({ first }) => (first ? "0px" : "40px")};
`;
const Image = styled.img`
  width: 343px;
  height: 160px;
  border-radius: 8px;
  justify-self: center;
  margin: 16px auto 0px auto;
  @media screen and (max-width: 374px) {
    width: 280px;
  }
`;
type TypeInfo = {
  infoStateOpen: (infoStateOpen: boolean) => void;
  setOpenModal: (setOpenModal: boolean) => void;
};
const InfoGlossary = ({ infoStateOpen, setOpenModal }: TypeInfo) => {
  return (
    <Container>
      <BaseNav
        inFirstPage={true}
        currentPage={infoStateOpen}
        showButton={false}
        title={text.label_title_how_to_use}
        goBack={infoStateOpen}
        setOpenModal={setOpenModal}
      />
      <Box>
        <Text first={true}>{text.label_details_search}</Text>
        <Image src={how_to_search} />
        <Text first={false}>{text.label_details_copy}</Text>
        <Image src={how_to_copy} />
        <Text first={false}>{text.label_details_close}</Text>
        <Image src={how_to_close} />
      </Box>
    </Container>
  );
};
export default InfoGlossary;
