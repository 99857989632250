import React from "react";
import styled from "styled-components";
import icon_white from "Asset/done_24px_white.svg";
const Container = styled.div`
  background: rgba(28, 28, 30, 0.6);
  max-width: 283px;
  width: fit-content;
  min-height: 40px;
  display: flex;
  z-index: 11;
  border-radius: 100px;
  background-color: #5a0003;
  align-items: center;
  align-self: center;
  bottom: 107px;
  z-index: 10;
  position: relative;
  @media screen and (max-width: 374px) {
    max-width: 300px;
  }
  
`;
const Icon = styled.img`
  color: ${({ theme }) => theme.colors.UIColorDarkGray};
  width: 17px;
  height: 13px;
  margin-left: 19px;
`;
const Text = styled.span`
  display: flex;
  font-size: 14px;
  color: ${({ theme }) => theme.colors.White};
  font-family: ${({ theme }) => theme.font.SF};
  font-weight: normal;
  justify-content: start;
  margin-left: 11px;
  margin-right: 16px;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  overflow: hidden;
`;
type TypeModalCopy = {
  word: string;
};
const ModalCopy = ({ word }: TypeModalCopy) => {
  return (
    <Container>
      <Icon src={icon_white}></Icon>
      <Text>Copied {word?.length > 20 ? word?.substring(0,20)+'...':word}</Text>
    </Container>
  );
};

export default ModalCopy;
