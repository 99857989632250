import React from "react";
import icon_germany from "Asset/Germany.png";
import icon_info from "Asset/Info.svg";
import text from "Data/Text";
import Letter from "./Letter";
import {
  NavBar,
  IconGermany,
  TextTitle,
  IconInfo,
  ButtonClose,
  TextClose,
  Container,
} from "./NavModalGlossary.styled";
import SearchBar from "./SearchBar";

type TypeNavModalGlossary = {
  setModalOpen: (stateModalOpen: boolean) => void;
  setInfoOpen: (stateInfoOpen: boolean) => void;
  groupCounts: any[];
  groups: any[];
  setCurrentScroll: any;
  searchOpen: (searchOpen: boolean) => void;
  setResultOpen: (setResultOpen: boolean) => void;
};
const NavModalGlossary = ({
  setModalOpen,
  setInfoOpen,
  groupCounts,
  groups,
  setCurrentScroll,
  searchOpen,
  setResultOpen,
}: TypeNavModalGlossary) => {
  const onClose = () => {
    setModalOpen(false);
  };
  const onClickInfo = () => {
    setInfoOpen(true);
  };
  return (
    <Container>
      <NavBar>
        <IconGermany src={icon_germany} />
        <TextTitle>{text.label_title_wine}</TextTitle>
        <IconInfo src={icon_info} onClick={onClickInfo} />
        <ButtonClose onClick={onClose}>
          <TextClose>{text.label_close}</TextClose>
        </ButtonClose>
      </NavBar>
      <Letter
        groupCounts={groupCounts}
        groups={groups}
        setCurrentScroll={setCurrentScroll}
      />
      <SearchBar
        setSearchOpen={searchOpen}
        disable={true}
        search={false}
        mainPage={true}
        setResultOpen={setResultOpen}
      />
    </Container>
  );
};
export default NavModalGlossary;
