import React, { useRef, useState } from "react";
import styled from "styled-components";
import icon_search from "Asset/search.svg";
import icon_exit from "Asset/Icon_exit.svg";
import text from "Data/Text";
import { useEffect } from "react";

const Container = styled.div<{ mainPage: boolean }>`
  display: block;
  height: 36px;
  margin: ${({ mainPage }) =>
    mainPage ? "16px 16px 19px 16px" : "0px 16px 0px 16px"};
`;
const Search = styled.div`
  background: rgba(118, 118, 128, 0.12);
  border-radius: 10px;
  width: 100%;
  height: 36px;
  border: none;
  display: flex;
  align-items: center;
`;
const IconSearch = styled.img`
  width: 16px;
  height: 16px;
  margin-left: 10px;
  justify-self: center;
`;
const IconExit = styled.img<{ show: boolean }>`
  width: 20px;
  height: 20px;
  justify-self: center;
  margin-right: 8px;
  display: ${({ show }) => (show ? "block" : "none")};
`;
const Form = styled.form`
  display: flex;
  width: 100%;
`;
const Text = styled.input`
  display: flex;
  width: 100%;
  height: 40px;
  border: none;
  font-size: 16px;
  font-family: ${({ theme }) => theme.font.SF};
  font-weight: normal;
  color: ${({ theme }) => theme.colors.UIColorBlack};
  align-self: center;
  margin-left: 8px;
  margin-right: 18px;
  outline: none;
  background-color: transparent;
  caret-color: #c42d31 !important;
`;

type TypeSearchBar = {
  setSearchOpen: (setSearchOpen: boolean) => void;
  setshowTextResult?: any;
  word?: any;
  disable: boolean;
  setListOpen?: any;
  setResultOpen: (setResultOpen: boolean) => void;
  search: boolean;
  mainPage: boolean;
};

const SearchBar = ({
  setSearchOpen,
  disable,
  word,
  setResultOpen,
  mainPage,
}: TypeSearchBar) => {
  const [canShowIcon, setShowIcon] = useState(false);
  const [textValue, setValue] = useState<string>("");
  const refInput = useRef<HTMLInputElement>(null);
  const GoToSearch = () => {
    if (disable) {
      setSearchOpen(true);
    }
  };

  const checkInput = (text: string) => {
    word(text);
    setValue(text);
    setShowIcon(true);
  };
  const onClose = () => {
    setShowIcon(false);
    setValue("");
    word("");
  };
  const SubmitSearch = () => {
    setSearchOpen(false);
    setResultOpen(true);
  };
  useEffect(() => {
    refInput?.current?.focus();
    refInput?.current?.click();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  return (
    <Container mainPage={mainPage}>
      <Search onClick={GoToSearch}>
        <IconSearch src={icon_search} />
        <Form
          role="search"
          className="search-form"
          onSubmit={(e) => {
            e.preventDefault();
            SubmitSearch();
          }}
        >
          <Text
            ref={refInput}
            autoComplete="off"
            className="search"
            type="search"
            id="search"
            disabled={disable}
            placeholder={text.label_search}
            onChange={(event) => checkInput(event.target.value)}
            value={textValue}
          />
        </Form>
        <IconExit show={canShowIcon} src={icon_exit} onClick={onClose} />
      </Search>
    </Container>
  );
};
export default SearchBar;
