import React from "react";
import Wine from "Asset/Wine.png";
import { Img, TextDetails, Topic, Details, Box } from "./LinkExpire.styled";
import BoxWrapScroll from "Components/Layout";
import { withRouter } from "react-router-dom";
import text from "Data/Text";
const LinkExpire = () => {
  return (
    <BoxWrapScroll>
      <Box>
        <Img src={Wine} />
        <TextDetails>
          <Topic>{text.label_title_expire}</Topic>
          <Details>{text.label_expire_details}</Details>
        </TextDetails>
      </Box>
    </BoxWrapScroll>
  );
};
export default withRouter(LinkExpire);
