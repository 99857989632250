import styled from "styled-components";

export const Box = styled.span`
  display: flex;
  flex-direction: column;
  height: 100%;
  overflow: hidden!important;
`;

export const Img = styled.img`
  z-index: -10;
  height: 100%;
  display: flex;
  align-self: center;
  position: absolute;
  @media screen and (min-width: ${({ theme }) => theme.breakpoints.md}) {
    align-self: flex-end;
  }
  @media screen and (min-width: ${({ theme }) => theme.breakpoints.lg}) {
    align-self: flex-end;
    margin-left: -15%;
  }
`;
export const TextDetails = styled.span`
  display: flex;
  flex-direction: column;
  margin-top: 48px;
  margin-left: 6.4%;
  @media screen and (min-width: ${({ theme }) => theme.breakpoints.md}) {
    margin-top: 80px;
    margin-left: 8.34%;
  }
  @media screen and (min-width: ${({ theme }) => theme.breakpoints.lg}) {
    margin-top: 80px;
    margin-left: 23%;
  }
`;
export const Topic = styled.span`
  font-size: 24px;
  color: ${({ theme }) => theme.colors.UIColorBlack};
  font-family: ${({ theme }) => theme.font.NewYork};
  @media screen and (min-width: ${({ theme }) => theme.breakpoints.md}) {
    font-size: 40px;
  }
`;

export const Details = styled.span`
  margin-top: 24px;
  max-width: 311px;
  font-family: ${({ theme }) => theme.font.SF};
  font-size: 14px;
  background: linear-gradient(169deg, #ffffff, rgba(255, 255, 255, 0));
  color: ${({ theme }) => theme.colors.UIColorBlack};
  @media screen and (min-width: ${({ theme }) => theme.breakpoints.md}) {
    max-width: 400px;
    font-size: 16px;
  }
`;
