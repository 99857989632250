import React from "react";
import arrow_back from "Asset/arrow_back.svg";
import text from "Data/Text";
import {
  IconBack,
  TextTitle,
  ButtonClose,
  TextClose,
  Container,
  IconBackButton,
} from "./Basenav.styled";

type TypeBaseNave = {
  title: string;
  showButton: boolean;
  currentPage: (currentPage: boolean) => void;
  goBack: (goBack: boolean) => void;
  setOpenModal: (setOpenModal: boolean) => void;
  inFirstPage: boolean;
};
const BaseNave = ({
  title,
  showButton,
  goBack,
  setOpenModal,
  currentPage,
  inFirstPage,
}: TypeBaseNave) => {
  const onBack = () => {
    if (inFirstPage === true) {
      goBack(true);
      currentPage(false);
    } else {
      goBack(false);
      currentPage(false);
    }
  };
  const onClose = () => {
    setOpenModal(false);
    localStorage.setItem("ListResult", "");
  };

  return (
    <Container>
      <IconBackButton onClick={onBack}>
        <IconBack src={arrow_back} />
      </IconBackButton>
      <TextTitle>{title}</TextTitle>
      <ButtonClose show={showButton} onClick={onClose}>
        <TextClose>{text.label_close}</TextClose>
      </ButtonClose>
    </Container>
  );
};
export default BaseNave;
